import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card, { CardProps } from "./components/Card";

function App() {
  const experiences: CardProps[] = [
    {
      title: "Senior Software Development Engineer Web",
      tag: "GovTech Education",
      description:
        "Assisted several teams, who were responsible for the Merdeka Mengajar Platform, by contributing to several services that focus on teachers in the hope that they can develop themselves by sharing experiences, stories, and solutions to problems they overcome, from one teacher to another. Actively developed many functions that were used by several teams at once to provide the best experience to Teachers, Principals, School Supervisors, and Heads of Education Services in following a series of performance assessment processes, so that they could be completed quickly, comfortably, and right on target.",
    },
    {
      title: "Engineering Manager",
      tag: "Mekari",
      description:
        "Help backend and frontend team members by making sure everything they need is available before they ask for it, including technical RFCs, and requests for resources. And actively participate in scrum events with POs, TPMs, and team members, both internally within the team or across teams.",
    },
    {
      title: "Senior Front-End Engineer",
      tag: "OVO",
      description:
        "Work with Loyalty team members to setup authentication page to be used by third parties, such as Grab, and Tokopedia. Provide better support for our users in a simple way, called Help Center. Setup a new dashboard from scratch using Nuxt, Vue, and Webpack. As well as to prepare some documentations for legacy services, and setup some coding guideline and standards.",
    },
    {
      title: "Senior Front-End Engineer",
      tag: "Bukalapak",
      description:
        "Help to solve tribe-wide issues for both on development or even production stage. Also, work with two teams under O2O tribe that handles Mitra Bukalapak make their life easier by providing access to virtual products, product management, and dozens of other features.",
    },
  ];

  const recommendations: CardProps[] = [
    {
      title: "Ibrahim Arief",
      tag: "VP of Engineering · Bukalapak",
      description:
        "Jo is a highly self-motivated individual that never hesitate to go the extra mile in building great web products for our customers. He is a great and seamless collaborator even though he lives 700 km away from the rest of the team. He frequently flies to Jakarta on his own volition to sync better with his team, and he is an all around dependable teammate to have.",
    },
    {
      title: "Azzam Jihad Ulhaq",
      tag: "Software Engineer · Mekari",
      description:
        "I had the opportunity to work with Jonathan during my tenure at Mekari. Jonathan drives and is passionate about the work he does. Apart from his excellent technical skills, he also had good leadership skills during his time as a leader in my team. He is very humble and cares about the team members. As a detail-oriented person, Jonathan pays attention to details that we often miss and saves the team from mistakes that shouldn't have happened. I'm grateful to have worked with Jonathan as my manager in my early career journey.",
    },
  ];

  return (
    <div className="mx-auto min-h-screen max-w-screen-xl px-6 py-12 font-sans md:px-12 md:py-20 lg:px-24 lg:py-0">
      {/* Dots */}
      <div className="fixed top-0 left-0 right-0 z-0">
        <img
          alt=""
          src="images/vector.svg"
          className="mx-auto mt-[-250px] z-0"
        />
      </div>

      {/* Ellipse */}
      <div className="fixed top-0 left-0 w-[840px] h-[592px] z-[1]">
        <img alt="" src="images/ellipse.png" className="z-[1]" />
      </div>

      <div className="relative lg:flex lg:justify-between lg:gap-4 z-20">
        <header className="lg:sticky lg:top-0 lg:flex lg:max-h-screen lg:w-1/2 lg:flex-col lg:justify-between lg:py-24">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              <h1 className="text-4xl font-semibold leading-10 text-slate-200">
                Jonathan Christianto
              </h1>
              <div className="flex items-center gap-2">
                <h2 className="text-base font-medium leading-6 text-slate-200">
                  Software Engineer
                </h2>
                <div className="text-base font-bold leading-6 text-slate-200">
                  &bull;
                </div>
                <div className="text-base font-medium leading-6 text-slate-400">
                  /ˈjänəTHən/
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <div className="text-base font-normal leading-6 text-slate-400">
                I build immersive digital experiences, transforming abstract
                ideas into tangible realities through the art of code.
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div className="text-base font-normal leading-6 text-slate-400">
              Currently focus in building user-centered, high-quality technology
              for a irreversible impact to Indonesia through education.
            </div>

            <div className="flex flex-row gap-6">
              <a
                title="Check out my GitHub profile"
                className="text-slate-400 hover:text-slate-200 no-underline"
                href="https://github.com/jochristianto"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="xl" />
              </a>
              <a
                title="Connect with me on LinkedIn"
                className="text-slate-400 hover:text-slate-200 no-underline"
                href="https://www.linkedin.com/in/jochristianto"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} size="xl" />
              </a>
              <a
                title="Reach out to me via email"
                className="text-slate-400 hover:text-slate-200 no-underline"
                href="mailto:hello@jonathanchristianto.com"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faEnvelope} size="xl" />
              </a>
              {/* <a
                className="text-slate-400 hover:text-slate-200 no-underline"
                href="https://www.linkedin.com/in/jochristianto"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="xl" />
              </a> */}
            </div>
          </div>
        </header>

        <main className="pt-20 lg:w-1/2 lg:py-24 flex flex-col gap-20">
          <div className="flex flex-col gap-2">
            <h3 className="text-2xl font-medium leading-8 text-slate-200">
              Resume
            </h3>
            <div className="flex flex-col gap-8">
              {experiences.map((experience, idx) => (
                <Card key={idx} {...experience} />
              ))}

              <a
                className="text-sm font-bold leading-5 text-slate-200 underline"
                href="https://www.linkedin.com/in/jochristianto"
                target="_blank"
                rel="noreferrer"
              >
                View full resume{" "}
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  size="xs"
                  className="ml-1"
                />
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-2xl font-medium leading-8 text-slate-200">
              Recommendations
            </h3>
            <div className="flex flex-col gap-8">
              {recommendations.map((recommendation, idx) => (
                <Card key={idx} {...recommendation} />
              ))}

              <a
                className="text-sm font-bold leading-5 text-slate-200 underline"
                href="https://www.linkedin.com/in/jochristianto"
                target="_blank"
                rel="noreferrer"
              >
                View full recommendations{" "}
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  size="xs"
                  className="ml-1"
                />
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-2xl font-medium leading-8 text-slate-200">
              Get in touch
            </h3>
            <div className="text-sm font-normal leading-5 text-slate-400">
              Although I'm not currently looking for any new opportunities, my
              inbox is always open. Whether you have a question or just want to
              say hi, I'll try my best to get back to you!
            </div>
            <a
              title="Reach out to me via email"
              className="mt-6 text-sm font-bold leading-5 text-slate-200 underline"
              href="mailto:hello@jonathanchristianto.com"
              target="_blank"
              rel="noreferrer"
            >
              Say hello
            </a>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
