import type { FC } from "react";

export interface CardProps {
  title: string;
  tag: string;
  description: string;
}

const Card: FC<CardProps> = ({ title, tag, description }) => {
  return (
    <div className="flex flex-col gap-3">
      <div>
        <h3 className="text-base font-bold leading-6 text-slate-200">
          {title}
        </h3>
        <div className="text-sm font-normal leading-5 text-slate-200">
          {tag}
        </div>
      </div>
      <div className="text-sm font-normal leading-5 text-slate-400">
        {description}
      </div>
    </div>
  );
};
export default Card;
